import * as React from "react";

import Layout from "../components/layout";

const pageTitle = "私たちについて";

const AboutUs = () => {
  return (
    <Layout pageTitle={pageTitle}>
      <p>テキスト</p>
    </Layout>
  );
};

export default AboutUs;
